<template>
  <div class="text-center lg:mx-32 my-32 text-gray-700 content">
    <template v-if="result.price">
      <p>
        Vous pouvez souscrire une assurance Cyber sécurité formule
        <strong>{{ result.formula }}</strong> à partir de :
      </p>

      <price-result :price="result.price" class="py-8"></price-result>

      <p class="mb-6">
        Un conseiller Groupama prendra contact avec vous pour établir un devis
        personnalisé très rapidement.
      </p>
    </template>
    <template v-else>
      <p class="mb-6">
        Votre situation nécessite une étude approfondie. Un conseiller prendra
        contact avec vous pour vous apportez un tarif.
      </p>
    </template>

    <p class="mb-6">
      Pour souscrire ou pour tout renseignement complémentaire, n’hésitez pas à
      nous contacter<br />
      par téléphone au <strong>{{ result.contactPhone }}</strong> (coût d'un
      appel local ou gratuit selon votre abonnement)<br />
      {{ result.contactOpeningHours }}<br />
      ou par mail :
      <a :href="`mailto:${result.contactEmail}`">{{ result.contactEmail }}</a>
    </p>

    <p class="text-xs" v-if="result.price">
      <span>
        (1) Offre soumise à conditions. Tarif indicatif TTC valable jusqu'au
        28/02/2025 (hors impact éventuel lié au mode de paiement) pour la
        souscription d'un contrat Groupama CYBER UP avec un plafond de garantie de
      </span>
      <span v-if="isEntreprise">150&nbsp;000&nbsp;€</span>
      <span v-else>75&nbsp;000&nbsp;€</span>
      <span>
        par année d'assurance. Conditions détaillées des formules de
        garantie et des options proposées en demandant un devis d'assurance
        personnalisé à un(e) chargé(e) de clientèle Groupama.
      </span>
    </p>
  </div>
</template>

<script>
import PriceResult from "@/components/base/PriceResult.vue"
export default {
  components: { PriceResult },
  props: {
    type: String,
    result: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    isEntreprise() {
      return this.type === "Entreprise" || this.type === "Association" || this.type === "Etablissement public";
    },
  }
};
</script>
